import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import {
    setParametrosPesquisa,
    limparParametrosPesquisa,
    setExecutarBusca
} from '../../reducers/actions/pesquisa';
import { KEY_RETURN } from 'keycode-js';
import {
    Formik,
    FastField,
    Field,
} from 'formik';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import validationSchema from './validationSchema';
import PropTypes from 'prop-types';
import { estadoInicialForm } from './estadoInicialForm';
import { temPermissaoRBAC } from '../../secutity/acl';
import {
    SttGrid,
    SttDatePicker,
    SttInput,
    SttMaskedInput,
    SttAutocomplete,
    SttButton,
    SttFormControl,
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    REQUISICAO,
    NUMERO,
    DATA_DE,
    DATA_ATE,
    UF_ORIGEM,
    UF_DESTINO,
    CIDADE_ORIGEM,
    CIDADE_DESTINO,
    NOME_PACIENTE,
    CPF_PACIENTE,
    TRANSPORTE,
    SITUACAO,
    REGIONAL,
    ALTA_HOSPITALAR,
    NUMERO_FATURA,
    MOTIVO_CANCELAMENTO
} from './fieldNames';
import { SITUACAO_PROCESSO, PERMISSOES, ETAPA_FLUXO_CANCELAMENTO } from '../../common/Constants';

const { LABEL } = SITUACAO_PROCESSO;

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const Form = (props) => {
    const {
        buscar,
        callbackAndamento,
        callbackBusca,
        count,
        executarBusca,
        limparParametrosPesquisa,
        order,
        orderBy,
        page,
        parametrosPesquisa,
        resetPageOrder,
        setExecutarBusca,
        setParametrosPesquisa,
        user
    } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const [ufs, setUfs] = useState([]);
    const [transportes, setTransportes] = useState([]);
    const [regionais, setRegionais] = useState([]);
    const [cidadesOrigem, setCidadesOrigem] = useState([]);
    const [cidadesDestino, setCidadesDestino] = useState([]);
    const [situacoes, setSituacoes] = useState([]);
    const [motivosCancelamento, setMotivosCancelamento] = useState([]);
    const [exibirMotiboCancelamento, setExibirMotivoCancelamento] = useState(false);

    const formRef = useRef()
    const classes = useStyles();
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    useEffect(() => {
        axios.get(`${UTILITARIOS_API_BASE_URL}/localizacao/estado`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setUfs(itens);
                }
            })
            .catch(err => console.log(err));

        axios.get(`${UTILITARIOS_API_BASE_URL}/regional-saude`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setRegionais(itens);
                }
            })
            .catch(err => console.log(err));

        axios.get(`${TFD_PASSAGENS_API_BASE_URL}/transporte-recomendavel`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setTransportes(itens);
                }
            })
            .catch(err => console.log(err));

        const sit = Object.entries(LABEL).map((e) => ({
            id: e[0],
            descricao: e[1]
        }));
        if (temPermissaoRBAC(user, PERMISSOES.VISUALIZACAO) && !temPermissaoRBAC(user, PERMISSOES.SOLICITACAO)) {
            sit.shift();
        }
        setSituacoes(sit);
    }, []);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    useEffect(() => {
        if (user) {
            if (temPermissaoRBAC(user, PERMISSOES.SOLICITACAO) || temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR_REGULADOR) ||
                temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR) || temPermissaoRBAC(user, PERMISSOES.ARQUIVAMENTO)) {
                setExibirMotivoCancelamento(true);
                axios.get(`${TFD_PASSAGENS_API_BASE_URL}/motivo-cancelamento/ativos?etapa-fluxo=${ETAPA_FLUXO_CANCELAMENTO.AMBOS}`, { headers: getHeaders() })
                    .then((response) => {
                        if (response.data) {
                            const { itens } = response.data.data;
                            setMotivosCancelamento(itens);
                        }
                    })
                    .catch(err => console.log(err));
            }
        }
    }, [user])

    useEffect(() => {
        if (executarBusca && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [executarBusca]);

    const handleChangeUf = (uf, origem = true) => {
        let setter;
        if (origem) {
            setter = setCidadesOrigem;
        } else {
            setter = setCidadesDestino;
        }

        if (uf) {
            axios.get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setter(itens);
                    } else {
                        setter([]);
                    }
                })
                .catch(err => console.log(err));
        } else {
            setter([]);
        }
    }

    const handleChangeKeyUp = (event) => {
        if (event.keyCode === KEY_RETURN) {
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={parametrosPesquisa}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting }) => {
                // Redux dispatcher
                setParametrosPesquisa(dados);

                setSubmitting(true);
                callbackAndamento(true);

                let params = {};
                if (dados.de) {
                    params.de = moment(dados.de).format('YYYY-MM-DD');
                }
                if (dados.ate) {
                    params.ate = moment(dados.ate).format('YYYY-MM-DD');
                }
                if (dados.nome) {
                    params.nome = dados.nome.trim();
                }
                if (dados.requisicao.trim()) {
                    params.requisicao = dados.requisicao.trim();
                }
                if (dados.numero.trim()) {
                    params.numero = dados.numero.trim();
                }
                if (dados.cpf) {
                    params.cpf = dados.cpf.replace(/\D+/g, '');
                }
                if (dados.transporte) {
                    params.transporte = dados.transporte.id;
                }
                if (dados.situacao) {
                    params.situacao = dados.situacao.id;
                }
                if (dados.ufOrigem) {
                    params.ufOrigem = dados.ufOrigem.id;
                }
                if (dados.ufDestino) {
                    params.ufDestino = dados.ufDestino.id;
                }
                if (dados.cidadeOrigem) {
                    params.cidadeOrigem = dados.cidadeOrigem.id;
                }
                if (dados.cidadeDestino) {
                    params.cidadeDestino = dados.cidadeDestino.id;
                }
                if (dados.regional) {
                    params.regional = dados.regional.id;
                }
                if (dados.altaHospitalar) {
                    params.altaHospitalar = true;
                }
                if (dados.motivoCancelamento) {
                    params.motivoCancelamento = dados.motivoCancelamento.id;
                }
                if (dados.numeroFatura.trim()) {
                    params.numeroFatura = dados.numeroFatura.trim();
                }
                if (orderBy && order) {
                    params.sort = orderBy;
                    params.direction = order;
                }

                const offset = (page * count);
                params.start = offset;
                params.count = count;

                axios.get(`${TFD_PASSAGENS_API_BASE_URL}/processo`, { params, headers: getHeaders() })
                    .then((response) => {
                        const { data } = response.data;
                        callbackBusca(data);
                    })
                    .catch(err => {
                        callbackBusca({
                            totalRegistros: 0,
                            itens: []
                        })
                    })
                    .finally(() => {
                        setSubmitting(false);
                        callbackAndamento(false);
                        setExecutarBusca();
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} sm={3} md={3} lg={3} xl={1}>
                                    <FastField name={REQUISICAO}>
                                        {({
                                            field,
                                            form: { setFieldError, setFieldTouched },
                                            meta
                                        }) => (
                                            <SttMaskedInput
                                                {...field}
                                                mask="requisicaoPassagem"
                                                label={strings.requisicao}
                                                error={meta.touched && meta.error ? true : false}
                                                onKeyUp={handleChangeKeyUp}
                                                onError={error => {
                                                    setFieldError(REQUISICAO, meta.error || error);
                                                }}
                                                onBlur={() => {
                                                    setFieldTouched(REQUISICAO, true);
                                                }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3} md={3} lg={3} xl={2}>
                                    <FastField name={NUMERO}>
                                        {({
                                            field
                                        }) => (
                                            <SttInput
                                                {...field}
                                                onKeyUp={handleChangeKeyUp}
                                                onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                                label={strings.numero}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3} md={2} lg={2} xl={2}>
                                    <FastField name={DATA_DE}>
                                        {({
                                            field: { name, value, },
                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                            meta
                                        }) => {
                                            return (
                                                <SttDatePicker
                                                    label={strings.dataInicial}
                                                    inputprops={{
                                                        name: name
                                                    }}
                                                    onKeyUp={handleChangeKeyUp}
                                                    error={meta.touched && meta.error ? true : false}
                                                    onError={error => {
                                                        setFieldError(DATA_DE, meta.error || error);
                                                    }}
                                                    value={value}
                                                    onBlur={() => {
                                                        setFieldTouched(DATA_DE, true);
                                                    }}
                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    onChange={date => setFieldValue(DATA_DE, date, true)}
                                                    onClose={() => setFieldTouched(DATA_DE, true)}
                                                />
                                            );
                                        }}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3} md={2} lg={2} xl={2}>
                                    <FastField name={DATA_ATE}>
                                        {({
                                            field: { name, value, },
                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                            meta
                                        }) => {
                                            return (
                                                <SttDatePicker
                                                    label={strings.dataFinal}
                                                    inputprops={{
                                                        name: name
                                                    }}
                                                    error={meta.touched && meta.error ? true : false}
                                                    onKeyUp={handleChangeKeyUp}
                                                    onError={error => {
                                                        setFieldError(DATA_ATE, meta.error || error);
                                                    }}
                                                    value={value}
                                                    onBlur={() => {
                                                        setFieldTouched(DATA_ATE, true);
                                                    }}
                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                    onChange={date => setFieldValue(DATA_ATE, date, true)}
                                                    onClose={() => setFieldTouched(DATA_ATE, true)}
                                                />
                                            );
                                        }}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3} md={2} lg={2} xl={1}>
                                    <Field name={UF_ORIGEM}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.ufOrigem
                                                }}
                                                getOptionLabel={option => (option?.sigla || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={ufs}
                                                value={value}
                                                onKeyUp={handleChangeKeyUp}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    const uf = item || null;
                                                    setFieldValue(UF_ORIGEM, uf);
                                                    setFieldValue(CIDADE_ORIGEM, null);
                                                    handleChangeUf(uf, true);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={4}>
                                    <Field name={CIDADE_ORIGEM}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue, values }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.cidadeOrigem
                                                }}
                                                disabled={!values.ufOrigem}
                                                getOptionLabel={option => (option?.nome || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={cidadesOrigem}
                                                value={value}
                                                onKeyUp={handleChangeKeyUp}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    const cidade = item || null;
                                                    setFieldValue(CIDADE_ORIGEM, cidade);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3} md={2} lg={2} xl={1}>
                                    <Field name={UF_DESTINO}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.ufDestino
                                                }}
                                                getOptionLabel={option => (option?.sigla || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={ufs}
                                                value={value}
                                                onKeyUp={handleChangeKeyUp}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    const uf = item || null;
                                                    setFieldValue(UF_DESTINO, uf);
                                                    setFieldValue(CIDADE_DESTINO, null);
                                                    handleChangeUf(uf, false);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={4}>
                                    <Field name={CIDADE_DESTINO}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue, values }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.cidadeDestino
                                                }}
                                                disabled={!values.ufDestino}
                                                getOptionLabel={option => (option?.nome || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={cidadesDestino}
                                                value={value}
                                                onKeyUp={handleChangeKeyUp}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    const cidade = item || null;
                                                    setFieldValue(CIDADE_DESTINO, cidade);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={4} xl={3}>
                                    <Field name={REGIONAL}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.regionalSaude
                                                }}
                                                getOptionLabel={option => (option?.descricao || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={regionais}
                                                value={value}
                                                onKeyUp={handleChangeKeyUp}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    const reg = item || null;
                                                    setFieldValue(REGIONAL, reg);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={5} md={4} lg={4} xl={2}>
                                    <FastField name={NOME_PACIENTE}>
                                        {({
                                            field
                                        }) => (
                                            <SttInput
                                                {...field}
                                                onKeyUp={handleChangeKeyUp}
                                                onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                                label={strings.nomePaciente}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={3} md={2} lg={2} xl={2}>
                                    <FastField name={CPF_PACIENTE}>
                                        {({
                                            field,
                                            form: { setFieldError, setFieldTouched },
                                            meta
                                        }) => (
                                            <SttMaskedInput
                                                {...field}
                                                mask="cpf"
                                                label={strings.cpfPaciente}
                                                error={meta.touched && meta.error ? true : false}
                                                onKeyUp={handleChangeKeyUp}
                                                onError={error => {
                                                    setFieldError(CPF_PACIENTE, meta.error || error);
                                                }}
                                                onBlur={() => {
                                                    setFieldTouched(CPF_PACIENTE, true);
                                                }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={4} md={3} lg={3} xl={2}>
                                    <Field name={TRANSPORTE}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.tipoTransporte
                                                }}
                                                getOptionLabel={option => (option?.descricao || '')}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={transportes}
                                                value={value}
                                                onKeyUp={handleChangeKeyUp}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    const transporte = item || null;
                                                    setFieldValue(TRANSPORTE, transporte);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12} sm={5} md={3} lg={3} xl={2}>
                                    <Field name={SITUACAO}>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue }
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.situacao
                                                }}
                                                getOptionLabel={option => (option?.descricao) || ''}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={situacoes}
                                                onKeyUp={handleChangeKeyUp}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => {
                                                    const sit = item || null;
                                                    setFieldValue(SITUACAO, sit);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                {
                                    exibirMotiboCancelamento &&
                                    <SttGrid item xs={12} sm={5} md={3} lg={3} xl={2}>
                                        <Field name={MOTIVO_CANCELAMENTO}>
                                            {({
                                                field: { name, value, onBlur },
                                                form: { setFieldValue }
                                            }) => (
                                                <SttAutocomplete
                                                    inputprops={{
                                                        name: name,
                                                        label: strings.motivoCancelamento
                                                    }}
                                                    getOptionLabel={option => (option?.descricao) || ''}
                                                    getOptionSelected={(option, val) => option?.id === val?.id}
                                                    options={motivosCancelamento}
                                                    onKeyUp={handleChangeKeyUp}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={(e, item) => {
                                                        const sit = item || null;
                                                        setFieldValue(MOTIVO_CANCELAMENTO, sit);
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </SttGrid>
                                }
                                <SttGrid item xs={12} sm={6} md={3} lg={2} xl={2}>
                                    <FastField name={NUMERO_FATURA}>
                                        {({
                                            field,
                                            form: { setFieldError, setFieldTouched },
                                            meta
                                        }) => (
                                            <SttMaskedInput
                                                {...field}
                                                mask="faturaPassagem"
                                                label={strings.numeroFatura}
                                                error={meta.touched && meta.error ? true : false}
                                                onError={error => {
                                                    setFieldError(NUMERO_FATURA, meta.error || error);
                                                }}
                                                onBlur={() => {
                                                    setFieldTouched(NUMERO_FATURA, true);
                                                }}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={5} md={3} lg={2} xl={2}>
                                    <Field name={ALTA_HOSPITALAR}>
                                        {({
                                            field,
                                            form: { values }
                                        }) => (
                                            <SttFormControl variant="outlined">
                                                <SttFormGroup row>
                                                    <SttFormControlLabel
                                                        control={
                                                            <SttCheckbox
                                                                {...field}
                                                                value={true}
                                                                color="primary"
                                                                checked={values.altaHospitalar}
                                                            />
                                                        }
                                                        label={strings.altaHospitalar}
                                                    />
                                                </SttFormGroup>
                                            </SttFormControl>
                                        )}
                                    </Field>
                                </SttGrid>
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => {
                                            resetPageOrder();
                                        }}
                                    >
                                        {strings.pesquisar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            limparParametrosPesquisa();
                                            resetForm({
                                                values: estadoInicialForm
                                            });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
};

Form.propTypes = {
    user: PropTypes.object.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    buscar: PropTypes.bool.isRequired,
    resetPageOrder: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.pesquisa.parametrosPesquisa,
        executarBusca: state.pesquisa.executarBusca
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisa(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisa()),
        setExecutarBusca: () => dispatch(setExecutarBusca(false))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);