export const TRANSPORTE = 'transporte';
export const TIPO_TRANSPORTE = 'tipoTransporte';
export const PONTO_REFERENCIA = 'pontoReferencia';
export const UF_ORIGEM = 'ufOrigem';
export const CIDADE_ORIGEM = 'cidadeOrigem';
export const UF_DESTINO = 'ufDestino';
export const CIDADE_DESTINO = 'cidadeDestino';
export const JUSTIFICATIVA = 'justificativa';
export const ANEXOS = 'anexos';
export const ANEXOS_EXCLUIDOS = 'anexosExcluidos';
export const TRANSPORTE_MODIFICADO = 'transporteModificado';
export const NOME_AEROPORTO = 'nomeAeroporto';