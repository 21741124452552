import React, { useContext, useEffect, useState } from "react";
import { OPERACAO } from '../../../common/Constants';
import {
    SttModal,
    SttAlerta,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import OperacaoVoltar from './voltar';
import OperacaoReabrir from './reabrir';
import OperacaoDevolver from './devolver';
import OperacaoSuspender from './suspender';
import OperacaoCancelar from './cancelar';
import OperacaoFatura from './fatura';
import OperacaoArquivar from './arquivar';
import OperacaoAnexarPassagem from './anexarCompraPassagem';
import OperacaoSelecionarMotorista from './selecionarMotorista';
import OperacaoEncaminharRegulador from './encaminharRegulador';
import { getHeaders } from '../../../request';
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import OperacaoDossiePaciente from "./dossiePaciente";

const switchOperacao = (operacao, tfd, alerta, callback, callbackSucesso) => {
    switch (operacao) {
        case OPERACAO.VOLTAR_PROCESSO:
            return <OperacaoVoltar
                callback={callback}
                situacao={tfd.situacao}
                id={tfd.id}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.REABRIR:
            return <OperacaoReabrir
                callback={callback}
                id={tfd.id}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.DEVOLVER:
            return <OperacaoDevolver
                callback={callback}
                id={tfd.id}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.SUSPENDER:
            return <OperacaoSuspender
                callback={callback}
                id={tfd.id}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.DOSSIE_PACIENTE:
            return <OperacaoDossiePaciente
                callback={callback}
                id={tfd.id}
                dossie={tfd.dossie_paciente}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.CANCELAR:
            return <OperacaoCancelar
                callback={callback}
                id={tfd.id}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.SELECIONAR_MOTORISTA:
            return <OperacaoSelecionarMotorista
                callback={callback}
                tfd={tfd}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.ENCAMINHAR_APROVACAO_REGULADOR:
            return <OperacaoEncaminharRegulador
                callback={callback}
                id={tfd.id}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.ARQUIVAR:
            return <OperacaoArquivar
                callback={callback}
                tfd={tfd}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.ANEXAR_COMPROVANTE_PASSAGEM:
            return <OperacaoAnexarPassagem
                callback={callback}
                tfd={tfd}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
        case OPERACAO.FATURA:
            return <OperacaoFatura
                callback={callback}
                tfd={tfd}
                alerta={alerta}
                callbackSucesso={callbackSucesso}
            />
    }
}

const OperacaoTfd = ({ operacao, tfd, setExibirModalOperacao, voltarPesquisa, exibirModalForm, setIdProcessoEspelho }) => {
    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [modalAberto, setModalAberto] = useState(true);
    const [tituloModal, setTituloModal] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    //Alerta 
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);

    const alerta = {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    };

    const callback = () => {
        setModalAberto(false);
        setExibirModalOperacao(false);
    }

    const callbackSucesso = () => {
        callback();
        voltarPesquisa(true);
    }

    const encaminharSolicitacaoViagem = () => {
        const dados = {
            id: tfd.id,
            transporte: tfd.tipo_transporte
        };

        setIsSubmitting(true);
        axios.post(`${TFD_PASSAGENS_API_BASE_URL}/encaminhar-viagem-processo`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (response.status === HttpStatus.OK) {
                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                    setTipoAlerta('success');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                                callbackSucesso();
                            }
                        }
                    ]);
                    setMostrarAlerta(true);
                } else {
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(data && data.message ? data.message : strings.msgErroEncaminharCompra);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            }
                        }
                    ]);
                    setMostrarAlerta(true);
                }


            })
            .catch(err => {
                console.log(err);
                const { data } = err;

                setTituloAlerta(strings.erro);
                setMensagemAlerta(data && data.message ? data.message : strings.msgErroEncaminharCompra);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => callbackSucesso);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    const aprovarSolicitacaoViagem = () => {
        const dados = {
            id: tfd.id
        };

        setIsSubmitting(true);
        axios.post(`${TFD_PASSAGENS_API_BASE_URL}/aprovar-processo`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (response.status === HttpStatus.OK) {
                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                    setTipoAlerta('success');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                                callbackSucesso();
                            }
                        }
                    ]);
                    setOnCloseAlerta(() => callbackSucesso);
                    setMostrarAlerta(true);
                } else {
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(data && data.message ? data.message : strings.msgErroAprovarProcesso);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                            }
                        }
                    ]);
                    setMostrarAlerta(true);
                }
            })
            .catch(err => {
                console.log(err);
                const { data } = err;

                setTituloAlerta(strings.erro);
                setMensagemAlerta(data && data.message ? data.message : strings.msgErroAprovarProcesso);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    const criarProcessoEspelho = () => {
        const dados = {
            idPassagem: tfd.id
        };

        setIsSubmitting(true);
        axios.post(`${TFD_PASSAGENS_API_BASE_URL}/processo-espelho`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (response.status === HttpStatus.CREATED) {
                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                    setTipoAlerta('success');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setIdProcessoEspelho(data.data.idPassagem);
                            }
                        }
                    ]);
                    setMostrarAlerta(true);
                } else {
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(data && data.message ? data.message : strings.msgErroCriarEspelho);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                setMostrarAlerta(false);
                                callback();
                            }
                        }
                    ]);
                    setOnCloseAlerta(() => callback);
                    setMostrarAlerta(true);
                }


            })
            .catch(err => {
                console.log(err);
                const { data } = err;

                setTituloAlerta(strings.erro);
                setMensagemAlerta(data && data.message ? data.message : strings.msgErroCriarEspelho);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                            callback();
                        }
                    }
                ]);
                setOnCloseAlerta(() => callback);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    useEffect(() => {
        if (operacao) {
            switch (operacao) {
                case OPERACAO.VOLTAR_PROCESSO:
                    setTituloModal(strings.voltar);
                    break;
                case OPERACAO.DOSSIE_PACIENTE:
                    setTituloModal(strings.dossiePaciente);
                    break;
                case OPERACAO.REABRIR:
                    setTituloModal(strings.reabrir);
                    break;
                case OPERACAO.DEVOLVER:
                    setTituloModal(strings.devolver);
                    break;
                case OPERACAO.SUSPENDER:
                    setTituloModal(strings.suspender);
                    break;
                case OPERACAO.CANCELAR:
                    setTituloModal(strings.cancelar);
                    break;
                case OPERACAO.SELECIONAR_MOTORISTA:
                    setTituloModal(strings.selecionarMotorista);
                    break;
                case OPERACAO.ENCAMINHAR_APROVACAO_REGULADOR:
                    setTituloModal(strings.selecionarMedicoRegulador);
                    break;
                case OPERACAO.FATURA:
                    setTituloModal(strings.fatura);
                    break;
                case OPERACAO.ARQUIVAR:
                    setTituloModal(strings.arquivar);
                    break;
                case OPERACAO.ANEXAR_COMPROVANTE_PASSAGEM:
                    setTituloModal(strings.anexarPassagem);
                    break;
                case OPERACAO.ENCAMINHAR_SOLICITACAO_VIAGEM:
                    encaminharSolicitacaoViagem();
                    break;
                case OPERACAO.APROVAR:
                    aprovarSolicitacaoViagem();
                    break;
                case OPERACAO.CRIAR_PROCESSO_ESPELHO:
                    criarProcessoEspelho();
                    break;
            }
        }
    }, [operacao]);

    return (
        <>
            {
                exibirModalForm ?
                    <SttModal
                        title={tituloModal}
                        open={modalAberto}
                        maxWidth="lg"
                        outModalClose={callback}
                        iconClose={callback}
                        fullWidth={true}
                        children={
                            switchOperacao(operacao, tfd, alerta, callback, callbackSucesso)
                        }
                    />
                    :
                    <SttLoading
                        open={isSubmitting}
                        text={strings.aguarde}
                    />
            }
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </>
    )

}

export default OperacaoTfd;