import React, { useRef, useState, useEffect, memo, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttFileSelect,
    SttHeading,
    SttInput,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FieldArray,
    FastField,
    Field
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { ETAPA_FLUXO_CANCELAMENTO } from '../../../common/Constants';

const validationSchema = (strings) => {
    return yup.object().shape({
        'justificativa': yup
            .string()
            .trim()
            .min(10, strings.justificativaInvalida)
            .nullable()
            .required(strings.campoObrigatorio),
        'motivo': yup
            .object().shape({
                id: yup.number(),
                descricao: yup.string(),
                exige_complemento: yup.string(),
                etapa_fluxo: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    },
    divExternaImagens: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'hidden'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    botaoMenos: {
        minWidth: 0
    },
    container: {
        marginBottom: 0
    }
}));

const FileSelect = memo((props) => {
    return (
        <SttFileSelect {...props} headers={getHeaders()} />
    )
});

const OperacaoCancelar = ({ callback, callbackSucesso, id, alerta }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const initialValues = {
        anexosCancelamento: [{}],
        justificativa: '',
        motivo: null
    };

    const [motivosCancelamento, setMotivosCancelamento] = useState([]);

    //Alerta 
    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    useEffect(() => {
        axios.get(`${TFD_PASSAGENS_API_BASE_URL}/motivo-cancelamento/ativos?etapa-fluxo=${ETAPA_FLUXO_CANCELAMENTO.AMBOS}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setMotivosCancelamento(itens);
                }
            })
            .catch(err => console.log(err));
    }, []);

    const validarAnexo = (value) => {
        let error;

        if (!value || !(value instanceof File)) {
            return null;
        }
        if (value.type !== 'image/jpeg' && value.type !== 'image/png' && value.type !== 'application/pdf' && value.type !== 'application/msword' &&
            value.type !== 'application/vnd.ms-excel' && value.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return strings.formatoArquivoInvalido;
        }
        return error;
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                const formData = new FormData();
                formData.append('id', id);
                formData.append('motivo', dados.motivo.id);

                let justificativa = dados.justificativa.trim();
                formData.append('justificativa', justificativa);

                dados.anexosCancelamento.forEach((anexo, index) => {
                    if (anexo && (anexo instanceof File)) {
                        formData.append(`anexosCancelamento.${index}`, anexo);
                    }
                });

                axios.post(`${TFD_PASSAGENS_API_BASE_URL}/cancelar-processo`, formData,  { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
                    .then((response) => {
                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                        resetForm({ values: initialValues });
                                        callbackSucesso();
                                    }
                                }
                            ]);
                            setOnCloseAlerta(() => callbackSucesso);
                            setMostrarAlerta(true);
                        } else {
                            setTituloAlerta(strings.erro);
                            setMensagemAlerta(data && data.message ? data.message : strings.erroCancelarProcesso);
                            setTipoAlerta('error');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        }


                    })
                    .catch(err => {
                        console.log(err);
                        const { data } = err;
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(data && data.message ? data.message : strings.erroCancelarProcesso);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: 'Ok',
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                    values
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <Field name='motivo'>
                                        {({
                                            field: { name, value, onBlur },
                                            form: { setFieldValue },
                                            meta,
                                        }) => (
                                            <SttAutocomplete
                                                inputprops={{
                                                    name: name,
                                                    label: strings.motivo,
                                                    required: true,
                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                }}
                                                getOptionLabel={option => (option?.descricao) || ''}
                                                getOptionSelected={(option, val) => option?.id === val?.id}
                                                options={motivosCancelamento}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={(e, item) => setFieldValue('motivo', item || null)}
                                            />
                                        )}
                                    </Field>
                                </SttGrid>
                                <SttGrid item xs={12}>
                                    <FastField name='justificativa'>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                multiline
                                                rows={5}
                                                required={true}
                                                {...field}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" }
                                                }}
                                                label={strings.justificativa}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12}>
                                    <SttHeading variant="h5" color="primary">{strings.anexosCancelamento}</SttHeading>
                                    <FieldArray name='anexosCancelamento' render={
                                        ({ remove, push }) => (
                                            <>
                                                <div className={classes.divExternaImagens}>
                                                    {
                                                        values.anexosCancelamento.map((anexo, indice) => (
                                                            <Field key={indice} name={`anexosCancelamento.${indice}`} validate={validarAnexo}>
                                                                {({
                                                                    field: { name, value },
                                                                    form: { setFieldValue },
                                                                    meta
                                                                }) => (
                                                                    <>
                                                                        <FileSelect
                                                                            onFileChange={(event) => {
                                                                                //Caso a imagem tenha sido excluída, remove a mesma do array de imagens no formulário e retira do array de imagens
                                                                                if (!event) {
                                                                                    if (values.anexosCancelamento.length > 1) {
                                                                                        remove(indice);
                                                                                        return;
                                                                                    }
                                                                                    setFieldValue(`anexosCancelamento[${indice}]`, {});
                                                                                    return;
                                                                                }

                                                                                if (event.target.files[0]) {
                                                                                    //Seta o valor da imagem no campo correspondente do formulário
                                                                                    setFieldValue(`anexosCancelamento[${indice}]`, event.target.files[0]);
                                                                                }
                                                                            }}
                                                                            file={value}
                                                                            inputprops={{
                                                                                name: name,
                                                                                label: strings.formatosArquivo,
                                                                                value: value?.name,
                                                                                error: meta.touched && meta.error ? meta.error : undefined
                                                                            }}
                                                                            accept={["image/*", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/pdf", 'application/vnd.ms-excel']}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Field>
                                                        ))
                                                    }
                                                </div>
                                                <SttButton
                                                    className={classes.botaoMais}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        let chave = (values.anexosCancelamento.length + 1);
                                                        let obj = {};
                                                        obj[chave] = {};
                                                        push({});
                                                    }}
                                                >
                                                    +
                                                </SttButton>
                                                <SttButton
                                                    className={classes.botaoMenos}
                                                    variant="contained"
                                                    disabled={values.anexosCancelamento.length === 1}
                                                    onClick={() => {
                                                        if (values.anexosCancelamento.length > 1) {
                                                            let indice = values.anexosCancelamento.length - 1;
                                                            remove(indice);
                                                        }
                                                    }}
                                                    color="primary">
                                                    -
                                                </SttButton>
                                            </>
                                        )}
                                    />
                                </SttGrid>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvando}
                                />
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        className={classes.button}
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoCancelar;