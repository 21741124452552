import React, { useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttGrid,
    SttInput,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';

const validationSchema = (strings) => {
    return yup.object().shape({
        'justificativa': yup
            .string()
            .min(10, strings.justificativaInvalida)
            .trim()
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const useStyles = makeStyles(theme => ({
    button: {
        marginBottom: theme.spacing(1)
    },
    divExternaImagens: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        overflowY: 'hidden'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },
    botaoMenos: {
        minWidth: 0
    },
    container: {
        marginBottom: 0
    }
}));

const OperacaoSuspender = ({ callback, callbackSucesso, id, alerta }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const classes = useStyles();

    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const initialValues = {
        justificativa: ''
    };

    //Alerta 
    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;
    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dados, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                const formData = new FormData();
                formData.append('id', id);
                formData.append('justificativa', dados.justificativa.trim());

                axios.post(`${TFD_PASSAGENS_API_BASE_URL}/suspender-processo`, formData, { headers: { ...getHeaders() } })
                    .then((response) => {
                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.operacaoSucesso);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                        resetForm({ values: initialValues });
                                        callbackSucesso();
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        } else {
                            setTituloAlerta(strings.erro);
                            setMensagemAlerta(data && data.message ? data.message : strings.msgErroDevolverProcesso);
                            setTipoAlerta('error');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        setMostrarAlerta(false);
                                    }
                                }
                            ]);
                            setMostrarAlerta(true);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        const { data } = err;
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(data && data.message ? data.message : strings.msgErroDevolverProcesso);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setOnCloseAlerta(() => callbackSucesso);
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm,
                    values
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <FastField name='justificativa'>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                multiline
                                                rows={5}
                                                required={true}
                                                {...field}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" }
                                                }}
                                                label={strings.justificativa}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        className={classes.button}
                                        disabled={isSubmitting}
                                    >
                                        {strings.confirmar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        className={classes.button}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoSuspender;