import React, { useState, memo, lazy, Suspense, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Formik } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttAlerta,
    SttHeading,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { setIdTfdPassagem } from '../../reducers/actions/solicitacao';
import Functions from '../../common/Functions';
import { useQuery } from '../../hooks';
import { temPermissaoRBAC } from '../../secutity/acl';
import { SITUACAO_PROCESSO, TIPO_PROCESSO, PERMISSOES } from '../../common/Constants';

const Geral = lazy(() => import('../../componentes/solicitacao/geral'));
const Itinerario = lazy(() => import('../../componentes/solicitacao/itinerario'));
const Paciente = lazy(() => import('../../componentes/solicitacao/paciente'));
const Transporte = lazy(() => import('../../componentes/solicitacao/transporte'));
const Acompanhante = lazy(() => import('../../componentes/solicitacao/acompanhante'));
const Agendamento = lazy(() => import('../../componentes/solicitacao/agendamento'));
const Estadual = lazy(() => import('../../componentes/solicitacao/estadual'));
const VinculoProcessoTfd = lazy(() => import('../../componentes/solicitacao/vinculo'));

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    button: {
        marginLeft: 0,
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const TIPO_SUBMIT = {
    RASCUNHO: 'RASCUNHO',
    ENCAMINHAR: 'ENCAMINHAR',
    SALVAR: 'SALVAR',
}

const Solicitacao = ({ user, idPassagem, setIdTfdPassagem }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings, user);
    const history = useHistory();
    const TFD_PASSAGENS_API_BASE_URL = global.gConfig.url_base_tfd_passagens;

    const query = useQuery();
    const idCodificado = query.get('id') || null;

    const [initialValues, setInitalValues] = useState(null);
    const [callbackVinculo, setCallbackVinculo] = useState(null);
    const [exibirModalVinculo, setExibirModalVinculo] = useState(false);
    const [filtroPesquisaVinculo, setFiltroPesquisaVinculo] = useState(null);
    const [bloquearBotoesEnvio, setBloquearBotoesEnvio] = useState(false);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: 'Ok',
        onClick: handleCloseAlerta
    }]);

    useEffect(() => {
        if (idCodificado) {
            const idDecodificado = window.atob(idCodificado);
            axios.get(`${TFD_PASSAGENS_API_BASE_URL}/verificacao/${idDecodificado}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data?.data) {
                        const { podeAlterar } = response.data.data;
                        if (podeAlterar) {
                            setIdTfdPassagem(idDecodificado);
                            return;
                        }
                        history.push("/pesquisa");
                    }
                })
                .catch(err => {
                    history.push("/pesquisa");
                });
        }
    }, [idCodificado]);

    useEffect(() => {
        if (idPassagem) {
            axios.get(`${TFD_PASSAGENS_API_BASE_URL}/processo/${idPassagem}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;

                        setInitalValues(Functions.formatarTfdParaAlteracao(data));
                    }
                })
                .catch(err => {
                    console.log(err)
                    const { data } = err;
                    setTituloAlerta(strings.erro);
                    setMensagemAlerta(data?.message ? data.message : strings.erroCarregarProcesso);
                    setTipoAlerta('error');
                    setOpcoesAlerta([{
                        title: strings.ok,
                        onClick: () => {
                            history.push("/pesquisa");
                            setIdTfdPassagem(null);
                        }
                    }]);
                    setOnCloseAlerta(() => () => {
                        history.push("/pesquisa");
                        setIdTfdPassagem(null);
                    });
                });
        }
    }, [idPassagem]);

    const enviarForm = (tipoSubmit, dados, setSubmitting, idVinculo) => {
        setSubmitting(true);

        const formData = Functions.formatarTfdParaSalvar(dados, tipoSubmit, idVinculo);

        let tipo = '';
        let titulo = '';
        let mensagem = '';
        axios.post(`${TFD_PASSAGENS_API_BASE_URL}/processo/${dados.id}`, formData,  { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                const { data } = response;
                tipo = 'success';
                titulo = 'Sucesso';
                mensagem = response.data.message;
                setOpcoesAlerta([{
                    title: 'Ok',
                    onClick: () => {
                        history.push("/pesquisa");
                        setIdTfdPassagem(null);
                    }
                }]);
                setOnCloseAlerta(() => () => {
                    history.push("/pesquisa");
                    setIdTfdPassagem(null);
                });
            })
            .catch(err => {
                const { response } = err;
                let msg = 'Não foi salvar os dados do processo.';
                tipo = 'error';
                titulo = 'Erro';
                mensagem = msg;
                if (response?.status === HttpStatus.BAD_REQUEST) {
                    const { data } = response;
                    let arrMensagem = [];
                    data.errors.forEach(error => {
                        arrMensagem.push(`- ${error.message}`);
                    });
                    msg = arrMensagem.join('\n');
                    titulo = data.message;
                    mensagem = msg;
                }

                setOpcoesAlerta([{
                    title: 'Ok',
                    onClick: handleCloseAlerta
                }]);
            })
            .finally(() => {
                setSubmitting(false);
                setTipoAlerta(tipo);
                setTituloAlerta(titulo);
                setMensagemAlerta(mensagem);
                setMostrarAlerta(true);
            });
    }

    const vincularProcessoIda = (tipoSubmit, data, setSubmitting) => {
        // Processos de volta podem ser vinculados a um processo de ida.
        if (data.itinerario.tipoProcesso === TIPO_PROCESSO.TIPO.VOLTA) {
            setTipoAlerta('confirmation');
            setTituloAlerta(strings.vincularIda);
            setMensagemAlerta(strings.msgVincularProcesso);
            setOpcoesAlerta([
                {
                    title: strings.sim,
                    onClick: () => {
                        setMostrarAlerta(false);
                        setCallbackVinculo({
                            callback: (idVinculo) => {
                                setExibirModalVinculo(false);
                                setFiltroPesquisaVinculo(null);
                                enviarForm(tipoSubmit, data, setSubmitting, idVinculo);
                                setCallbackVinculo(null);
                            }
                        });
                        setFiltroPesquisaVinculo({
                            id: data.id,
                            idTfd: data.idTfd
                        })
                        setExibirModalVinculo(true);
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        enviarForm(tipoSubmit, data, setSubmitting);
                        setMostrarAlerta(false);
                    }
                }
            ]);
            setMostrarAlerta(true);
            return;
        }
        // Processos de IDA ou IDA+VOLTA não precisam ser vinculados.
        enviarForm(tipoSubmit, data, setSubmitting);
    }

    const submitForm = rascunho => (data, { setSubmitting }) => {
        setSubmitting(false);
        let tipoSubmit = TIPO_SUBMIT.RASCUNHO;

        if (!rascunho) {
            tipoSubmit = TIPO_SUBMIT.ENCAMINHAR; // Encaminha o processo para o estadual
            if (temPermissaoRBAC(user, [PERMISSOES.ENCAMINHAR, PERMISSOES.ENCAMINHAR_REGULADOR, PERMISSOES.ARQUIVAMENTO])) {
                tipoSubmit = TIPO_SUBMIT.SALVAR; // Salvamento de processos pelo estadual ou outro perfil com este tipo de permissão
            }
            setTipoAlerta('confirmation');
            setTituloAlerta(strings.atencao);
            setMensagemAlerta(strings.msgSalvar);
            setOpcoesAlerta([
                {
                    title: strings.confirmar,
                    onClick: () => {
                        setMostrarAlerta(false);
                        vincularProcessoIda(tipoSubmit, data, setSubmitting);
                    }
                },
                {
                    title: strings.cancelar,
                    onClick: handleCloseAlerta
                }
            ]);
            setMostrarAlerta(true);
            return;
        }

        // Salvar rascunho faz a submissão direta.
        enviarForm(tipoSubmit, data, setSubmitting);
    }

    const onSubmit = submitForm(false);
    const onSubmitRascunho = submitForm(true);

    return (
        initialValues
            ?
            <>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                >
                    {
                        ({
                            values,
                            isSubmitting,
                            handleSubmit,
                            setSubmitting,
                            resetForm
                        }) => {
                            return (
                                <SttContainer>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.solicitacaoDeTfdPassagem}{values.id}</SttHeading>
                                        {/* Dados gerais */}
                                        <SttExpansionPanel
                                            title={strings.dadosGerais}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Geral setBloquearBotoesEnvio={setBloquearBotoesEnvio} />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        {/* Itinerário */}
                                        <SttExpansionPanel
                                            title={strings.itinerario}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Itinerario />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        {/* Dados do paciente */}
                                        <SttExpansionPanel
                                            title={strings.dadosPaciente}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Paciente />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        {/* Transporte */}
                                        <SttExpansionPanel
                                            title={strings.transporte}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Transporte acoesAlerta={{
                                                        setMostrarAlerta,
                                                        setTituloAlerta,
                                                        setTipoAlerta,
                                                        setMensagemAlerta,
                                                        setOpcoesAlerta,
                                                    }} />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        {/* Acompanhante */}
                                        <SttExpansionPanel
                                            title={strings.acompanhanteDoador}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Acompanhante acoesAlerta={{
                                                        setMostrarAlerta,
                                                        setTituloAlerta,
                                                        setTipoAlerta,
                                                        setMensagemAlerta,
                                                        setOpcoesAlerta,
                                                    }} />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        {/* Agendamento e documentação */}
                                        <SttExpansionPanel
                                            title={strings.agendamentoDocumentacao}
                                            classegriditem={classes.expansionPanel}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <Agendamento />
                                                </Suspense>
                                            }
                                        />
                                        <Divider />
                                        {
                                            temPermissaoRBAC(user, [PERMISSOES.ENCAMINHAR, PERMISSOES.ENCAMINHAR_REGULADOR, PERMISSOES.ARQUIVAMENTO]) &&
                                            <>
                                                {/* Sessão extra para admin e tfd-estadual */}
                                                <SttExpansionPanel
                                                    title={strings.dadosAlteracaoEstadual}
                                                    classegriditem={classes.expansionPanel}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <Estadual />
                                                        </Suspense>
                                                    }
                                                />
                                                <Divider />
                                            </>
                                        }
                                        <div className={classes.buttonWrapper}>
                                            {
                                                temPermissaoRBAC(user, PERMISSOES.SOLICITACAO) &&
                                                (values.situacao === SITUACAO_PROCESSO.SITUACAO.RASCUNHO) &&
                                                <SttButton
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    disabled={isSubmitting || bloquearBotoesEnvio}
                                                    onClick={() => onSubmitRascunho(values, { setSubmitting, resetForm })}
                                                    nomarginleft="true"
                                                >
                                                    {strings.salvarRascunho}
                                                </SttButton>
                                            }
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                className={classes.button}
                                                color="primary"
                                                disabled={isSubmitting || bloquearBotoesEnvio}
                                            >
                                                {
                                                    temPermissaoRBAC(user, [PERMISSOES.ENCAMINHAR, PERMISSOES.ENCAMINHAR_REGULADOR, PERMISSOES.ARQUIVAMENTO]) ?
                                                        strings.salvar :
                                                        strings.encaminhar
                                                }
                                            </SttButton>
                                        </div>
                                    </form>
                                    <SttLoading
                                        open={isSubmitting}
                                        text={strings.salvandoSolicitacao}
                                    />
                                    <Alerta
                                        open={mostrarAlerta}
                                        title={tituloAlerta}
                                        message={mensagemAlerta}
                                        type={tipoAlerta}
                                        options={opcoesAlerta}
                                        onClose={onCloseAlerta}
                                    />
                                </SttContainer>
                            )
                        }
                    }
                </Formik>

                {
                    exibirModalVinculo &&
                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                        <VinculoProcessoTfd
                            tfd={filtroPesquisaVinculo}
                            callbackVinculo={callbackVinculo.callback}
                        />
                    </Suspense>
                }
            </>
            :
            <div className={classes.carregando}>
                <SttCircularProgress color="primary" />
            </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        idPassagem: state.solicitacao.idPassagem
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIdTfdPassagem: (id) => dispatch(setIdTfdPassagem(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Solicitacao);