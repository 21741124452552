import React, { useContext, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttHidden,
    SttText,
    SttTranslateHook,
    SttMenu,
    SttMenuItem,
    SttListItemIcon,
    SttListItemText
} from '@stt-componentes/core';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { OPERACAO, PERFIL, SITUACAO_PROCESSO } from '../../common/Constants';
import Form from './form';
import Detalhes from './detalhes';
import moment from 'moment';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IconButton } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { temPerfilRBAC } from '../../secutity/acl';
import { connect } from 'react-redux';
import OperacaoTfd from './operacoes';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    table: {
        overflow: 'auto'
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        REQUISICAO: 'requisicao',
        CIDADE_ORIGEM: 'cidade_origem',
        CIDADE_DESTINO: 'cidade_destino',
        PACIENTE: 'nome_paciente',
        DATA_IDA: 'data_ida',
        DATA_VOLTA: 'data_volta',
        NUMERO_ACOMPANHANTES: 'numero_acompanhantes',
        TIPO_TRANSPORTE: 'tipo_transporte',
        SITUACAO: 'situacao',
        REGIONAL_SAUDE: 'regional_saude'
    }
};

const { LABEL, COR } = SITUACAO_PROCESSO;

const SolicitacoesTfdPassagens = ({ user }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [solicitacoes, setSolicitacoes] = useState([]);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [rowsPerPage, setRowPerPage] = useState(global.gConfig.pagination.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);

    const [idSolicitacaoSelecionada, setIdSolicitacaoSelecionada] = useState(null);
    const [tfdDossie, setTfdDossie] = useState(null);
    const [exibirModalOperacao, setExibirModalOperacao] = useState(false);

    const resetPageOrder = () => {
        setPage(global.gConfig.pagination.start);
        setRowPerPage(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
    }

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setRowPerPage(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const callbackBusca = (dados) => {
        setTotalRegistros(parseInt(dados.totalRegistros));
        setSolicitacoes(dados.itens);
        setBuscar(false);
    }

    const handleClickVoltar = (atualizarBusca) => {
        setIdSolicitacaoSelecionada(null);
        if (atualizarBusca) {
            setBuscar(true);
        }
    }

    const getOrdenacao = (campo) => {
        return orderBy === campo;
    }

    const getDirecao = (campo) => {
        return (orderBy === campo) ? order : ORDENACAO.DIRECAO.ASC;
    }

    return (
        !idSolicitacaoSelecionada
            ?
            <>
                <Form
                    callbackBusca={callbackBusca}
                    callbackAndamento={setBuscaEmAndamento}
                    page={page}
                    count={rowsPerPage}
                    orderBy={orderBy}
                    order={order}
                    buscar={buscar}
                    resetPageOrder={resetPageOrder}
                />

                <TableContainer className={classes.tableWrapper} component={Paper}>
                    <SttTable>
                        <SttTableHead>
                            <SttTableRow>
                                <SttHidden xsDown>
                                    <SttTableCell width="5%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.REQUISICAO)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.REQUISICAO}
                                            direction={getDirecao(ORDENACAO.CAMPOS.REQUISICAO)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.REQUISICAO)}>
                                            {strings.requisicao}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="11%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.CIDADE_ORIGEM)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.CIDADE_ORIGEM}
                                            direction={getDirecao(ORDENACAO.CAMPOS.CIDADE_ORIGEM)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_ORIGEM)}>
                                            {strings.cidadeOrigem}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="11%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.CIDADE_DESTINO)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.CIDADE_DESTINO}
                                            direction={getDirecao(ORDENACAO.CAMPOS.CIDADE_DESTINO)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_DESTINO)}>
                                            {strings.cidadeDestino}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="14%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.REGIONAL_SAUDE)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.REGIONAL_SAUDE}
                                            direction={getDirecao(ORDENACAO.CAMPOS.REGIONAL_SAUDE)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.REGIONAL_SAUDE)}>
                                            {strings.regionalSaude}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="13%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.PACIENTE)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.PACIENTE}
                                            direction={getDirecao(ORDENACAO.CAMPOS.PACIENTE)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.PACIENTE)}>
                                            {strings.nomePaciente}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="7%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.DATA_IDA)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.DATA_IDA}
                                            direction={getDirecao(ORDENACAO.CAMPOS.DATA_IDA)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_IDA)}>
                                            {strings.dataIda}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="7%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.DATA_VOLTA)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.DATA_VOLTA}
                                            direction={getDirecao(ORDENACAO.CAMPOS.DATA_VOLTA)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_VOLTA)}>
                                            {strings.dataRetorno}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="6%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.NUMERO_ACOMPANHANTES)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.NUMERO_ACOMPANHANTES}
                                            direction={getDirecao(ORDENACAO.CAMPOS.NUMERO_ACOMPANHANTES)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO_ACOMPANHANTES)}>
                                            {strings.acompanhantes}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="9%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.TIPO_TRANSPORTE)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.TIPO_TRANSPORTE}
                                            direction={getDirecao(ORDENACAO.CAMPOS.TIPO_TRANSPORTE)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.TIPO_TRANSPORTE)}>
                                            {strings.tipoTransporte}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="11%" sortDirection={getOrdenacao(ORDENACAO.CAMPOS.SITUACAO)}>
                                        <SttTableSortLabel
                                            active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
                                            direction={getDirecao(ORDENACAO.CAMPOS.SITUACAO)}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}>
                                            {strings.situacao}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                    <SttTableCell width="4%" align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box', backgroundColor: 'white' }}>
                                        {strings.opcoes}
                                    </SttTableCell>
                                </SttHidden>
                                <SttHidden smUp>
                                    <SttTableCell width="100%" colSpan="2">{strings.solicitacoesPassagens}</SttTableCell>
                                </SttHidden>
                            </SttTableRow>
                        </SttTableHead>
                        <SttTableBody>
                            {
                                buscaEmAndamento
                                    ?
                                    <SttTableRow key={-1}>
                                        <SttTableCell colSpan={11}
                                            align="center">
                                            <SttCircularProgress />
                                        </SttTableCell>
                                    </SttTableRow>
                                    :
                                    solicitacoes.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={11}
                                                align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.requisicao}</SttTableCell>
                                                    <SttTableCell>{row.cidade_estado_origem}</SttTableCell>
                                                    <SttTableCell>{row.cidade_estado_destino}</SttTableCell>
                                                    <SttTableCell>{row.regional_saude}</SttTableCell>
                                                    <SttTableCell>{row.nome_paciente}</SttTableCell>
                                                    <SttTableCell>{row.data_ida_formatada ? moment.utc(row.data_ida_formatada, 'DD/MM/YYYY').format('DD/MM/YYYY') : null}</SttTableCell>
                                                    <SttTableCell>{row.data_volta_formatada ? moment.utc(row.data_volta_formatada, 'DD/MM/YYYY').format('DD/MM/YYYY') : null}</SttTableCell>
                                                    <SttTableCell align="center">{row.numero_acompanhantes}</SttTableCell>
                                                    <SttTableCell>{row.tipo_transporte}</SttTableCell>
                                                    <SttTableCell>
                                                        <Chip className={classes.chip}
                                                            style={{ backgroundColor: COR[row.situacao] }}
                                                            label={LABEL[row.situacao]}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.requisicao}:</b> {row.requisicao}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.origem}:</b> {row.cidade_estado_origem}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.destino}:</b> {row.cidade_estado_destino}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.regional}:</b> {row.regional_saude}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.paciente}:</b> {row.nome_paciente}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.ida}:</b> {row.data_ida_formatada ? moment.utc(row.data_ida_formatada, 'DD/MM/YYYY').format('DD/MM/YYYY') : null}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.volta}:</b> {row.data_volta_formatada ? moment.utc(row.data_ida_formatada, 'DD/MM/YYYY').format('DD/MM/YYYY') : null}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.acompanhantes}:</b> {row.numero_acompanhantes}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.transporte}:</b> {row.tipo_transporte}
                                                        </SttText>
                                                        <Chip className={classes.chip}
                                                            style={{ marginTop: '4px', backgroundColor: COR[row.situacao] }}
                                                            label={LABEL[row.situacao]}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box', backgroundColor: 'white' }}>
                                                    <PopupState variant="popover">
                                                        {(popupState) => (
                                                            <>
                                                                <IconButton aria-haspopup="true" {...bindTrigger(popupState)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                                <SttMenu {...bindMenu(popupState)}>
                                                                    <SttMenuItem
                                                                        onClick={() => {
                                                                            popupState.close();
                                                                            setIdSolicitacaoSelecionada(row.id);
                                                                        }}
                                                                    >
                                                                        <SttListItemIcon>
                                                                            <VisibilityIcon />
                                                                        </SttListItemIcon>
                                                                        <SttListItemText primary={strings.visualizar} />
                                                                    </SttMenuItem>
                                                                    {
                                                                        (row.situacao !== SITUACAO_PROCESSO.SITUACAO.RASCUNHO) &&
                                                                        (temPerfilRBAC(user, [PERFIL.TFD_ESTADUAL, PERFIL.ADMINISTRADOR, PERFIL.ADMIN_TELEMEDICINA])) &&
                                                                        <SttMenuItem
                                                                            onClick={() => {
                                                                                popupState.close();
                                                                                setExibirModalOperacao(true);
                                                                                setTfdDossie(row);
                                                                            }}
                                                                        >
                                                                            <SttListItemIcon>
                                                                                <FolderOpenIcon />
                                                                            </SttListItemIcon>
                                                                            <SttListItemText primary={strings.dossiePaciente} />
                                                                        </SttMenuItem>
                                                                    }
                                                                </SttMenu>
                                                            </>
                                                        )}
                                                    </PopupState>
                                                </SttTableCell>

                                            </SttTableRow>
                                        ))
                            }
                        </SttTableBody>
                    </SttTable>
                </TableContainer>
                {
                    solicitacoes.length > 0 &&
                    <SttTablePagination
                        rowsPerPageOptions={[10, 20, 40, 60]}
                        component="div"
                        count={totalRegistros}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={strings.linhasPorPagina}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                    />
                }
                {
                    exibirModalOperacao &&
                    <OperacaoTfd
                        setExibirModalOperacao={setExibirModalOperacao}
                        setIdProcessoEspelho={() => null}
                        exibirModalForm={true}
                        operacao={OPERACAO.DOSSIE_PACIENTE}
                        tfd={tfdDossie}
                        voltarPesquisa={handleClickVoltar}
                    />
                }
            </>
            :
            <Detalhes
                id={idSolicitacaoSelecionada}
                voltar={handleClickVoltar}
                setIdSolicitacaoSelecionada={setIdSolicitacaoSelecionada}
            />
    )

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(SolicitacoesTfdPassagens);